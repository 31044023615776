import React from 'react';
import type { NextPage } from 'next';
import { DefaultPageLayout } from '@/lib/layouts';
import { Hero, Categories } from '@/features/Home/components';
import styles from '@/features/Home/styles/HomePage.module.scss';

const Home: NextPage = () => (
    <DefaultPageLayout title="Home page" description="Generated by create next app" className={styles.main}>
        <Hero />
        <Categories />
    </DefaultPageLayout>
);

export default Home;
